@import "./styles/fonts.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Circular', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-mian: #b55340;
  --color-gray: #a4a8a9;
}
.page {
  will-change: transform;
}

.page-enter {
  transform: translate3d(100%, 0, 0);
}

.page-enter-active,
.page-enter-done {
  transform: translate3d(0, 0, 0);
  transition: transform 0.5s;
}

.page-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.page-exit-active {
  opacity: 0;
  transform: translate3d(-50%, 0, 0);
  transition: opacity 0.5s, transform 0.5s;
}

.c-card {
  opacity: 0;
}

.card-enter {
  opacity: 0;
  transform: translate3d(0, 15px, 0);
}

.card-enter-active,
.card-enter-done {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: opacity 1s, transform 1s;
}