@font-face {
  font-family: 'Circular';
  font-weight: 400;
  src: local('Circular'), url(../fonts/circular-book.woff) format('woff');
}

@font-face {
  font-family: 'Circular';
  font-weight: 600;
  src: local('Circular'), url(../fonts/CircularStd-Medium.woff) format('woff');
}

@font-face {
  font-family: 'TiemposHeadline';
  font-weight: 600;
  src: local('TiemposHeadline'), url(../fonts/TiemposHeadline-Semibold.woff) format('woff');
}

