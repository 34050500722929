.tabWrapper {
  border-bottom: 1px solid rgba(35, 46, 64, 0.15);
  .tabWrapper__arrowIcon {
    transform: scaleY(-1);
  }
  .tabWrapper__tabInner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease-in-out;
    position: relative;
    &:hover {
      transition: all 0.3s ease-in-out;
    }
  }
  .tabWrapper__tabSelectorList {
    width: 100%;
    height: fit-content;
    min-width: 18vw;
    flex-basis: calc(50% - 14px);
    box-shadow: 0 7px 34px 0 rgba(0, 0, 0, 0.2),
      inset 1px 0 0 rgba(0, 0, 0, 0.13), 0 11px 34px 0 rgba(0, 0, 0, 0.13);
    top: 64px;
    max-height: 258px;
    padding: 24px;
    transition: all 0.3s ease-in-out;
    transform: translateY(10px);
    z-index: 2;
    overflow: auto;
  }
  .tabWrapper__tabSelectorList :first-child {
    padding-top: unset;
  }
  .tabWrapper__tabSelectorList :last-child {
    padding-bottom: unset;
  }
  .tabWrapper__tabSelectorOpen {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    overflow: auto;
  }
}
