.root {
  .tabButton {
    transform: scaleX(0);
    transition: all 0.5s ease-in-out;

    &--activeTab {
      background: #b55340;
      transform: scaleX(1);
    }
  }
}
