.openButton {
  transition: all 0.4s ease-in-out;
  border-radius: 0.375rem;
  color: #000;

  &::before {
    transform: scaleY(0);
    transform-origin: 50% 100%;
    transition: all 0.4s ease-in-out;
  }

  &:hover {
    color: #ffffff;

    &::before {
      transform: scaleY(1);
    }
  }
}
.filterOpenButton {
  border-radius: 44px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #232e40;
  width: 133px;
  justify-content: center;

  @media only screen and (max-width: 660px) {
    font-size: 12px !important;
    padding: 4px 16px;
    width: unset !important;
    height: 40px;
  }
}
