.selectorWrapper {
  display: flex;
  @media only screen and (max-width: 768px) {
    display: block;
    position: revert;
  }

  .selectorInner {
    width: 133px;
    height: 40px;
    //background: transparent;
    border: 1px solid #6f7782 !important;
    display: flex;

    transition: all 0.3s ease-in-out;
    position: relative;

    @media only screen and (max-width: 660px) {
      width: 100%;
      min-width: 14px;
      position: revert;
      font-size: 12px;
      padding: 4px 16px;
    }
    &:hover {
      background-color: #cce3e2;
      transition: all 0.3s ease-in-out;
      border: 1px solid transparent;
    }
  }
  .selectorActive {
    background: #cce3e2;
    border-radius: 44px;
  }
  .selectorList {
    width: 400px;
    height: 308px;
    min-width: 18vw;
    border-radius: 12px;
    flex-basis: calc(50% - 14px);
    box-shadow: 0 7px 34px 0 rgba(0, 0, 0, 0.2),
      inset 1px 0 0 rgba(0, 0, 0, 0.13), 0 11px 34px 0 rgba(0, 0, 0, 0.13);
    top: 50px;
    max-height: 400px;
    padding: 20px 20px 0 20px;
    transition: all 0.3s ease-in-out;
    transform: translateY(10px);
    z-index: 2;
    overflow: auto;
    @media only screen and (max-width: 768px) {
      width: calc(100vw) !important;
      max-width: revert !important;
      position: absolute;
      top: 64px;
      left: 0;
      border-radius: revert;
    }
  }

  .singleSelectorList {
    max-width: 320px;
    max-height: 484px;
    height: fit-content;
    width: max-content;
    right: 0;
  }
  .selectorSubList {
    -webkit-overflow-scrolling: touch;
  }

  .selectorSubList::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  .selectorSubList::-webkit-scrollbar:horizontal {
    height: 16px;
    background-color: rgba(255, 255, 255, 0);
  }

  .selectorSubList::-webkit-scrollbar:vertical {
    width: 16px;
    background-color: rgba(255, 255, 255, 0);
  }

  .selectorSubList::-webkit-scrollbar-track,
  .selectorSubList::-webkit-scrollbar-thumb {
    border: 7px solid rgba(255, 255, 255, 0);
    background-clip: padding-box;
    border-radius: 25px;
  }

  .selectorSubList::-webkit-scrollbar-track {
    background-color: #e1e1e1;
    border-radius: 25px;
  }

  .selectorSubList::-webkit-scrollbar-thumb {
    background-color: #a4a8a9;
    &:hover {
      border: 7px solid rgba(255, 255, 255, 0);
      cursor: pointer;
    }
  }

  .selectorOpen {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    overflow: auto;
  }
  .selectorLink {
    font-size: 18px;
    line-height: 1.6;
    letter-spacing: 0.6px;
    padding: 0 0 16px 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    color: #333a46;
  }
  .singleSelectorLink {
    transition: background-color 0.2s ease-in;

    &:hover {
      transition: background-color 0.2s ease-in;
    }
  }
  .labelSubText {
    font-size: 10px;
  }
  .inputWithIcon input[type='text'] {
    padding-left: 40px;
  }
  .inputWithIcon input[type='text']:focus-visible {
    outline: none;
  }
  .searchIcon {
    position: absolute;
    top: 36%;
    left: 8%;
    transition: 0.3s;
  }
}
