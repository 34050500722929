.contentContainer::-webkit-scrollbar {
  -webkit-appearance: none;
}
.contentContainer::-webkit-scrollbar:vertical {
  width: 11px;
}

.contentContainer::-webkit-scrollbar:horizontal {
  height: 11px;
}

.contentContainer::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: #a4a8a9;
  background-clip: padding-box;
}